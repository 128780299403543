import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  useTheme,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  ChevronLeft,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { oratricslogo } from "../Assets/index";
import {
  HomeOutlined,
  PersonOutline,
  BookOutlined,
  AssessmentOutlined,
  ClassOutlined,
  StarOutline,
  HistoryOutlined,
  Logout,
  AssignmentOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
const { styled } = require("@mui/system");

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const menuItems = [
  { text: "Home", icon: <HomeOutlined />, path: "/" },
  {
    text: "My Class",
    icon: <BookOutlined />,
    path: "/myclass",
   
  },
  {
    text: "Homework",
    icon: <BookOutlined />,
    path: "/myclass",
    submenu: [
      { text: "Assign Homework", icon: <AssignmentOutlined />, path: "/assign-homework" },
      { text: "Check Homework", icon: <CheckCircleOutline />, path: "/check-homework" },
    ],
  },
  { text: "Demo Request", icon: <PersonOutline />, path: "/checkdemo" },
  { text: "My Student", icon: <AssessmentOutlined />, path: "/Student" },
  { text: "Quiz", icon: <ClassOutlined />, path: "/studentQuizList" },
  { text: "My Course", icon: <StarOutline />, path: "/MyCourse" },
  { text: "Earning", icon: <HistoryOutlined />, path: "/Earning" },
  { text: "Old Dashboard", icon: <HomeOutlined />, path: "/olddashboard" },
];

const Sidebar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [openSubMenu, setOpenSubMenu] = useState({});
  const [userName, setUserName] = useState("");

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserName(user.name || "User");
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleMenuItemClick = (path, hasSubMenu, text) => {
    if (!hasSubMenu) {
      navigate(path);
      if (!isNonMobile) {
        setIsSidebarOpen(false);
      }
    } else {
      setOpenSubMenu((prev) => ({ ...prev, [text]: !prev[text] }));
    }
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.text.primary,
              boxSizing: "border-box",
              width: drawerWidth,
              boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.08)",
              transition: "width 0.3s ease-in-out",
              overflowX: "hidden",
            },
          }}
        >
          <Box width="100%" height="100%" display="flex" flexDirection="column">
            {/* Logo Section */}
            <FlexBetween p={3} mb={2}>
              <Box display="flex" alignItems="center" justifyContent="flex-start">
                <img src={oratricslogo} alt="Oratrics Logo" style={{ height: "60px" }} />
              </Box>
              {!isNonMobile && (
                <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)} sx={{ color: theme.palette.text.secondary }}>
                  <ChevronLeft />
                </IconButton>
              )}
            </FlexBetween>

            <Divider sx={{ my: 0, borderColor: theme.palette.divider, opacity: 0.5 }} />

            <List sx={{ flexGrow: 1, paddingX: 1 }}>
              {menuItems.map(({ text, icon, path, submenu }) => (
                <Box key={text} mb={submenu ? 0 : 1.2}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => handleMenuItemClick(path, !!submenu, text)}
                      sx={{
                        borderRadius: "12px",
                        mx: 2,
                        py: 1.5,
                        backgroundColor: pathname === path ? theme.palette.primary.lighter : "transparent",
                        color: pathname === path ? theme.palette.primary.dark : theme.palette.text.primary,
                        transition: "background-color 0.2s ease-in-out",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.lightest,
                        },
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "40px", color: pathname === path ? theme.palette.primary.dark : theme.palette.text.secondary }}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontWeight: 500 }} primary={text} />
                      {submenu ? (openSubMenu[text] ? <ExpandLess sx={{ color: theme.palette.text.secondary }} /> : <ExpandMore sx={{ color: theme.palette.text.secondary }} />) : null}
                    </ListItemButton>
                  </ListItem>
                  {submenu && (
                    <Collapse in={openSubMenu[text]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {submenu.map((subItem) => (
                          <ListItemButton
                            key={subItem.text}
                            onClick={() => handleMenuItemClick(subItem.path, false)}
                            sx={{
                              pl: 5,
                              mx: 2,
                              my: 0.8,
                              borderRadius: "10px",
                              backgroundColor: pathname === subItem.path ? theme.palette.primary.lightest : "transparent",
                              color: pathname === subItem.path ? theme.palette.primary.dark : theme.palette.text.primary,
                              transition: "background-color 0.2s ease-in-out",
                              "&:hover": {
                                backgroundColor: theme.palette.primary.lightest,
                              },
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "40px", color: pathname === subItem.path ? theme.palette.primary.dark : theme.palette.text.secondary }}>
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ fontWeight: 500, fontSize: "0.9rem" }} primary={subItem.text} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </Box>
              ))}
            </List>

            {/* User Profile & Logout */}
            <Box sx={{ p: 3, pt: 2, display: "flex", alignItems: "center", gap: 1.5, borderTop: `1px solid ${theme.palette.divider}`, mt: 2 }}>
              <Avatar sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.dark, fontWeight: 600 }}>{userName.charAt(0)}</Avatar>
              <Box flexGrow={1}>
                <Typography variant="body1" fontWeight={500}>{userName}</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: "0.85rem" }}>Teacher</Typography>
              </Box>
              <Tooltip title="Logout">
                <IconButton color="inherit" sx={{ color: theme.palette.text.secondary }}>
                  <Logout sx={{ fontSize: "1.1rem" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;