import React, { useState, useEffect, forwardRef } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Avatar,
  IconButton,
  Modal,
  Stack,
  Chip,
  Tooltip,
  Fade,
  Zoom,
  Grow
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SchoolIcon from '@mui/icons-material/School';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import baseUrl from '../config';
import { useNavigate } from 'react-router-dom';

dayjs.extend(utc);

// Transition Component for Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Styled Components
const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': { 
    backgroundColor: theme.palette.primary.main, 
    height: 4,
    borderRadius: 2
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.95rem',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2.5),
    minHeight: 48,
    '&.Mui-selected': { 
      color: theme.palette.primary.main,
    },
    transition: 'all 0.3s ease',
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '20px',
  boxShadow: '0 12px 32px rgba(0, 0, 0, 0.08)',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.95), rgba(245,248,250,0.95))',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.06)',
  transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)',
    borderColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontSize: '0.875rem',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
    transition: 'background-color 0.2s ease',
  },
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.grey[200],
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
  },
}));

const MyClass = () => {
  const navigate = useNavigate();

  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rescheduledDates, setRescheduledDates] = useState({});
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState(null);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [selectedStudentName, setSelectedStudentName] = useState('');
  const [selectedCourseTitle, setSelectedCourseTitle] = useState('');
  const [openChaptersDialog, setOpenChaptersDialog] = useState(false);
  const [selectedCourseChapters, setSelectedCourseChapters] = useState([]);
  const [selectedCourseTitleForChapters, setSelectedCourseTitleForChapters] = useState('');

  const fetchClasses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}api/teachers/classes`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { page, limit: 10 },
      });
      setClasses(response.data.data || []);
      setTotalPages(response.data.pagination?.totalPages || 1);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, [page]);

  const handleTabChange = (event, newValue) => setSelectedTab(newValue);
  const handlePaginationChange = (event, value) => setPage(value);

  const handleMarkAttendance = async (classScheduleId, studentId) => {
    try {
      await axios.post(
        `${baseUrl}api/teachers/mark-attendance-schedule/${classScheduleId}`,
        { studentId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      fetchClasses();
    } catch (error) {
      console.error(error);
    }
  };

  const approveReschedule = async (enrollmentId, classScheduleId) => {
    try {
      const response = await axios.post(
        `${baseUrl}api/teachers/approve-reschedule`,
        { enrollmentId, classScheduleId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      fetchClasses();
    } catch (error) {
      console.error('Error approving reschedule:', error);
    }
  };

  const unholdEnrollment = async (enrollmentId) => {
    const holdEndDate = new Date().toISOString();
    const classesToReschedule = classes
      .find((c) => c.nextClass?.enrollmentId === enrollmentId)
      ?.classSchedules?.filter((classSchedule) => {
        const classDate = new Date(classSchedule.startDate);
        const holdStartDate = new Date(classSchedule.holdStartDate);
        const holdEndDate = new Date(holdEndDate);
        return classDate >= holdStartDate && classDate <= holdEndDate;
      })
      .map((classSchedule, index) => ({
        rescheduledDate: rescheduledDates[index] || classSchedule.startDate,
      })) || [];

    try {
      await axios.put(`${baseUrl}api/enrollment/${enrollmentId}/unhold`, {
        holdEndDate,
        rescheduledDates: classesToReschedule,
      });
      fetchClasses();
    } catch (error) {
      console.error('Error unholding enrollment:', error);
    }
  };

  const handleMarkOnLeave = async (enrollmentId) => {
    try {
      const holdStartDate = new Date().toISOString();
      await axios.put(
        `${baseUrl}api/teachers/enrollment/${enrollmentId}/mark-on-leave`,
        { holdStartDate },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setOpenLeaveModal(false);
      setSelectedEnrollmentId(null);
      fetchClasses();
    } catch (error) {
      console.error('Error marking student on leave:', error);
    }
  };

  const openSchedulePopup = (schedules, studentName, courseTitle) => {
    setSelectedSchedules(schedules || []);
    setSelectedStudentName(studentName);
    setSelectedCourseTitle(courseTitle);
    setOpenScheduleDialog(true);
  };

  const openChaptersPopup = (chapters, courseTitle, studentDetails) => {
    navigate('/LearnClasses', {
      state: {
        chapters,
        courseTitle,
        studentDetails: {
          ...studentDetails,
          grade: studentDetails.grade,
          startDateStudent: studentDetails.startDateStudent,
          timeStudent: studentDetails.timeStudent,
          totalClasses: studentDetails.totalClasses,
        },
      },
    });
  };

  const closeDialogs = () => {
    setOpenScheduleDialog(false);
    setOpenChaptersDialog(false);
    setSelectedSchedules([]);
    setSelectedStudentName('');
    setSelectedCourseTitle('');
    setSelectedCourseChapters([]);
    setSelectedCourseTitleForChapters('');
  };

  if (loading) return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8ed 100%)'
    }}>
      <Box textAlign="center">
        <CircularProgress size={60} thickness={5} sx={{ color: 'primary.main' }} />
        <Typography variant="h6" sx={{ mt: 3, color: 'text.secondary' }}>
          Loading your classes...
        </Typography>
      </Box>
    </Box>
  );

  if (error) return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8ed 100%)'
    }}>
      <Box textAlign="center" sx={{ p: 4, borderRadius: 3, bgcolor: 'background.paper', boxShadow: 1 }}>
        <Typography variant="h5" color="error" sx={{ mb: 2, fontWeight: 600 }}>
          Error Loading Data
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {error}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={fetchClasses}
          sx={{ borderRadius: 2, textTransform: 'none' }}
        >
          Retry
        </Button>
      </Box>
    </Box>
  );

  const filteredClasses = classes.filter((classInfo) => {
    if (selectedTab === 0) return true;
    if (selectedTab === 1) return classInfo.attendedClasses < classInfo.totalClasses;
    if (selectedTab === 2) return classInfo.attendedClasses === classInfo.totalClasses;
    if (selectedTab === 3) return classInfo.isDemoClass;
    return false;
  });

  const sortedClasses = [...filteredClasses].sort((a, b) => {
    const getNextDate = (classInfo) => {
      if (classInfo.todayClass?.length) return new Date(classInfo.todayClass[0].startDateStudent);
      if (classInfo.nextClass) return new Date(classInfo.nextClass.startDateStudent);
      return new Date(8640000000000000);
    };
    return getNextDate(a) - getNextDate(b);
  });

  return (
    <Box sx={{ 
      p: { xs: 2, sm: 3, md: 4 }, 
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8ed 100%)'
    }}>
      <StyledPaper>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              color: 'text.primary',
              mb: 1,
              fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.5rem' },
              background: 'linear-gradient(90deg, #3b82f6 0%, #1e40af 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline-block'
            }}
          >
            My Classes
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
            <SchoolIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
            Manage and view all your teaching sessions
          </Typography>
        </Box>

        <StyledTabs 
          value={selectedTab} 
          onChange={handleTabChange} 
          variant="scrollable" 
          scrollButtons="auto" 
          sx={{ 
            mb: 4,
            '& .MuiTabs-scrollButtons': {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          <Tab label="All Classes" icon={<EventNoteIcon sx={{ fontSize: '1.1rem' }} />} />
          <Tab label="Pending" icon={<PendingActionsIcon sx={{ fontSize: '1.1rem' }} />} />
          <Tab label="Completed" icon={<CheckCircleIcon sx={{ fontSize: '1.1rem' }} />} />
          <Tab label="Demo" icon={<PersonIcon sx={{ fontSize: '1.1rem' }} />} />
        </StyledTabs>

        <Grid container spacing={3}>
          {sortedClasses.map((classInfo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Grow in={true} timeout={(index + 1) * 200}>
                <StyledCard>
                  <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                    {/* Header with Student Info */}
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      mb: 2,
                      position: 'relative'
                    }}>
                      <Avatar 
                        sx={{ 
                          bgcolor: 'primary.main', 
                          mr: 2, 
                          width: 48, 
                          height: 48,
                          boxShadow: '0 4px 12px rgba(59, 130, 246, 0.3)'
                        }}
                      >
                        <PersonIcon fontSize="medium" />
                      </Avatar>
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{ 
                            fontWeight: 700,
                            color: 'text.primary',
                            fontSize: { xs: '1.1rem', sm: '1.25rem' },
                            lineHeight: 1.2
                          }}
                        >
                          {classInfo.studentName}
                        </Typography>
                        <Typography 
                          variant="subtitle2" 
                          color="text.secondary"
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            mt: 0.5
                          }}
                        >
                          <SchoolIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                          {classInfo.courseTitle}
                        </Typography>
                      </Box>
                      {classInfo.isDemoClass && (
                        <Chip
                          label="Demo"
                          size="small"
                          color="secondary"
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            fontWeight: 600,
                            fontSize: '0.7rem',
                            textTransform: 'uppercase'
                          }}
                        />
                      )}
                    </Box>

                    <Divider sx={{ 
                      my: 2, 
                      borderColor: 'divider',
                      borderBottomWidth: 2,
                      opacity: 0.5
                    }} />

                    {/* Class Statistics */}
                    <Stack 
                      direction="row" 
                      spacing={2} 
                      justifyContent="space-between" 
                      mb={2}
                      sx={{ px: 1 }}
                    >
                      <Tooltip title="Total Classes" arrow>
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Total
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 700 }}>
                            {classInfo.totalClasses}
                          </Typography>
                        </Box>
                      </Tooltip>
                      
                      <Tooltip title="Attended Classes" arrow>
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Attended
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 700, color: 'success.main' }}>
                            {classInfo.attendedClasses}
                          </Typography>
                        </Box>
                      </Tooltip>
                      
                      <Tooltip title="Pending Classes" arrow>
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Pending
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 700, color: 'warning.main' }}>
                            {classInfo.pendingClasses?.length || 0}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Stack>
                    
                    <ProgressBar
                      variant="determinate"
                      value={(classInfo.attendedClasses / classInfo.totalClasses) * 100 || 0}
                      sx={{ mb: 3 }}
                    />

                    {/* Current/Next Class */}
                    <Box sx={{ 
                      mb: 3,
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: 'action.hover',
                      borderLeft: '4px solid',
                      borderColor: 'primary.main'
                    }}>
                      {classInfo.todayClass?.length > 0 ? (
                        <Box>
                          <Typography 
                            variant="subtitle1" 
                            sx={{ 
                              fontWeight: 700, 
                              color: 'primary.main',
                              mb: 1,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <CalendarTodayIcon sx={{ mr: 1 }} />
                            Today's Class
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1
                            }}
                          >
                            <EventNoteIcon sx={{ mr: 1, fontSize: '1rem' }} />
                            {dayjs.utc(classInfo.todayClass[0].startDateStudent).format('DD MMM YYYY')}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              mb: 2
                            }}
                          >
                            <AccessTimeIcon sx={{ mr: 1, fontSize: '1rem' }} />
                            {classInfo.todayClass[0].timeStudent}
                          </Typography>
                          {classInfo.todayClass[0].joinUrl && (
                            <Button
                              variant="contained"
                              href={classInfo.todayClass[0].joinUrl}
                              target="_blank"
                              fullWidth
                              sx={{
                                bgcolor: 'success.main',
                                '&:hover': { bgcolor: 'success.dark' },
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontWeight: 600,
                                py: 1,
                                boxShadow: '0 4px 12px rgba(16, 185, 129, 0.2)'
                              }}
                            >
                              Join Class Now
                            </Button>
                          )}
                        </Box>
                      ) : classInfo.nextClass ? (
                        <Box>
                          <Typography 
                            variant="subtitle1" 
                            sx={{ 
                              fontWeight: 700, 
                              color: 'primary.main',
                              mb: 1,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <CalendarTodayIcon sx={{ mr: 1 }} />
                            Next Class
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1
                            }}
                          >
                            <EventNoteIcon sx={{ mr: 1, fontSize: '1rem' }} />
                            {dayjs.utc(classInfo.nextClass.startDateStudent).format('DD MMM YYYY')}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              mb: 2
                            }}
                          >
                            <AccessTimeIcon sx={{ mr: 1, fontSize: '1rem' }} />
                            {classInfo.nextClass.timeStudent}
                          </Typography>
                          {classInfo.nextClass.joinUrl && (
                            <Button
                              variant="contained"
                              href={classInfo.nextClass.joinUrl}
                              target="_blank"
                              fullWidth
                              sx={{
                                bgcolor: 'primary.main',
                                '&:hover': { bgcolor: 'primary.dark' },
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontWeight: 600,
                                py: 1,
                                boxShadow: '0 4px 12px rgba(59, 130, 246, 0.2)'
                              }}
                            >
                              Join Class
                            </Button>
                          )}
                        </Box>
                      ) : (
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ fontStyle: 'italic' }}
                        >
                          No upcoming classes scheduled
                        </Typography>
                      )}
                    </Box>

                    {/* Action Buttons */}
                    <Stack direction="column" spacing={2}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                        {classInfo.courseChapters?.length > 0 && (
                          <Button
                            variant="outlined"
                            startIcon={<MenuBookIcon />}
                            onClick={() => openChaptersPopup(classInfo.courseChapters, classInfo.courseTitle, {
                              studentName: classInfo.studentName,
                              courseTitle: classInfo.courseTitle,
                              totalClasses: classInfo.totalClasses,
                              attendedClasses: classInfo.attendedClasses,
                              grade: classInfo.grade,
                              startDateStudent: classInfo.nextClass?.startDateStudent,
                              timeStudent: classInfo.nextClass?.timeStudent,
                            })}
                            fullWidth
                            sx={{
                              color: 'primary.main',
                              borderColor: 'primary.main',
                              borderRadius: '8px',
                              textTransform: 'none',
                              fontWeight: 600,
                              '&:hover': { 
                                bgcolor: 'primary.light', 
                                borderColor: 'primary.main',
                                boxShadow: '0 4px 12px rgba(59, 130, 246, 0.1)'
                              },
                            }}
                          >
                            View Chapters
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          startIcon={<CalendarTodayIcon />}
                          onClick={() => openSchedulePopup(classInfo.classSchedules, classInfo.studentName, classInfo.courseTitle)}
                          fullWidth
                          sx={{
                            bgcolor: 'primary.main',
                            '&:hover': { bgcolor: 'primary.dark' },
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontWeight: 600,
                            boxShadow: '0 4px 12px rgba(59, 130, 246, 0.2)'
                          }}
                        >
                          Schedules
                        </Button>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setOpenLeaveModal(true);
                            setSelectedEnrollmentId(classInfo.nextClass?.enrollmentId);
                          }}
                          fullWidth
                          sx={{
                            color: 'error.main',
                            borderColor: 'error.main',
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontWeight: 600,
                            '&:hover': { 
                              bgcolor: 'error.light', 
                              borderColor: 'error.main',
                              boxShadow: '0 4px 12px rgba(239, 68, 68, 0.1)'
                            },
                          }}
                        >
                          Mark Leave
                        </Button>
                        {classInfo.holdStartDate && (
                          <Button
                            variant="contained"
                            onClick={() => unholdEnrollment(classInfo.nextClass?.enrollmentId)}
                            fullWidth
                            sx={{
                              bgcolor: 'success.main',
                              '&:hover': { bgcolor: 'success.dark' },
                              borderRadius: '8px',
                              textTransform: 'none',
                              fontWeight: 600,
                              boxShadow: '0 4px 12px rgba(16, 185, 129, 0.2)'
                            }}
                          >
                            Unhold
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  </CardContent>
                </StyledCard>
              </Grow>
            </Grid>
          ))}
        </Grid>

        {sortedClasses.length === 0 && (
          <Box sx={{ 
            p: 4, 
            textAlign: 'center',
            borderRadius: 3,
            backgroundColor: 'background.default',
            boxShadow: 1
          }}>
            <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
              No classes found
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {selectedTab === 0 
                ? "You don't have any classes yet." 
                : selectedTab === 1 
                  ? "No pending attendance classes." 
                  : selectedTab === 2 
                    ? "No completed classes yet." 
                    : "No demo classes scheduled."}
            </Typography>
          </Box>
        )}

        {totalPages > 1 && (
          <Box sx={{ 
            mt: 4, 
            display: 'flex', 
            justifyContent: 'center',
            '& .MuiPaginationItem-root': {
              fontWeight: 600,
              '&.Mui-selected': {
                boxShadow: '0 4px 8px rgba(59, 130, 246, 0.2)'
              }
            }
          }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePaginationChange}
              color="primary"
              shape="rounded"
              size="large"
            />
          </Box>
        )}
      </StyledPaper>

      {/* Schedule Dialog */}
      <Dialog 
        open={openScheduleDialog} 
        onClose={closeDialogs} 
        maxWidth="lg" 
        fullWidth
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            overflow: 'hidden'
          }
        }}
      >
        <StyledDialogTitle>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            <CalendarTodayIcon sx={{ mr: 1, verticalAlign: 'middle' }} /> 
            {selectedStudentName} - {selectedCourseTitle} Schedules
          </Typography>
          <IconButton 
            onClick={closeDialogs} 
            sx={{ 
              color: 'common.white',
              '&:hover': {
                backgroundColor: alpha('#fff', 0.1)
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent sx={{ p: 0 }}>
          {selectedSchedules.length > 0 ? (
            <Table sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Time</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedSchedules.map((schedule, index) => (
                  <StyledTableRow key={schedule.classScheduleId || index}>
                    <TableCell>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {dayjs.utc(schedule.startDateStudent).format('ddd, DD MMM YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {schedule.timeStudent}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={schedule.status}
                        size="small"
                        sx={{
                          fontWeight: 600,
                          backgroundColor: schedule.status === 'completed' 
                            ? 'success.light' 
                            : schedule.status === 'pending' 
                              ? 'warning.light' 
                              : 'error.light',
                          color: schedule.status === 'completed' 
                            ? 'success.dark' 
                            : schedule.status === 'pending' 
                              ? 'warning.dark' 
                              : 'error.dark',
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" spacing={1} justifyContent="center">
                        {schedule.joinUrl && (
                          <Tooltip title="Join Class" arrow>
                            <Button
                              variant="contained"
                              href={schedule.joinUrl}
                              target="_blank"
                              size="small"
                              sx={{
                                minWidth: 90,
                                bgcolor: 'primary.main',
                                '&:hover': { bgcolor: 'primary.dark' },
                                borderRadius: '6px',
                                textTransform: 'none',
                                fontWeight: 600,
                              }}
                            >
                              Join
                            </Button>
                          </Tooltip>
                        )}
                        {schedule.status === 'pending' && (
                          <Tooltip title="Mark Attendance" arrow>
                            <Button
                              variant="contained"
                              startIcon={<EventAvailableIcon />}
                              onClick={() => handleMarkAttendance(schedule.classScheduleId, schedule.studentId)}
                              size="small"
                              sx={{
                                minWidth: 90,
                                bgcolor: 'success.main',
                                '&:hover': { bgcolor: 'success.dark' },
                                borderRadius: '6px',
                                textTransform: 'none',
                                fontWeight: 600,
                              }}
                            >
                              Mark
                            </Button>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Box sx={{ 
              p: 4, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              textAlign: 'center'
            }}>
              <EventNoteIcon sx={{ fontSize: 60, color: 'text.disabled', mb: 2 }} />
              <Typography variant="h6" color="text.secondary">
                No schedules available
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                There are no class schedules for this student yet.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2, borderTop: `1px solid`, borderColor: 'divider' }}>
          <Button
            onClick={closeDialogs}
            sx={{ 
              color: 'primary.main', 
              fontWeight: 600, 
              textTransform: 'none',
              borderRadius: '8px',
              px: 3,
              '&:hover': {
                backgroundColor: 'primary.light'
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mark on Leave Modal */}
      <Modal 
        open={openLeaveModal} 
        onClose={() => setOpenLeaveModal(false)}
        closeAfterTransition
      >
        <Fade in={openLeaveModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', sm: 400 },
              bgcolor: 'background.paper',
              borderRadius: '16px',
              boxShadow: 24,
              p: { xs: 3, sm: 4 },
              outline: 'none'
            }}
          >
            <Typography variant="h5" sx={{ 
              fontWeight: 700,
              mb: 2,
              color: 'primary.main',
              display: 'flex',
              alignItems: 'center'
            }}>
              <PendingActionsIcon sx={{ mr: 1 }} />
              Confirm Leave
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
              Are you sure you want to mark this student on leave starting from today?
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                onClick={() => setOpenLeaveModal(false)}
                variant="outlined"
                sx={{ 
                  color: 'text.secondary', 
                  textTransform: 'none', 
                  fontWeight: 600,
                  borderRadius: '8px',
                  px: 3
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleMarkOnLeave(selectedEnrollmentId)}
                sx={{
                  bgcolor: 'primary.main',
                  '&:hover': { bgcolor: 'primary.dark' },
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontWeight: 600,
                  px: 3
                }}
              >
                Confirm
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default MyClass;