import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Box,
  Chip,
  IconButton,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import baseUrl from '../config';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import GradeIcon from '@mui/icons-material/Grade';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';

// Styled Components
const HomeworkCard = styled(Card)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
}));

const CardSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StatusChip = styled(Chip)(({ status }) => ({
  fontWeight: 600,
  fontSize: '0.85rem',
  borderRadius: '4px',
  ...(status === 'pending' && {
    backgroundColor: '#fff3e0',
    color: '#e65100',
  }),
  ...(status === 'completed' && {
    backgroundColor: '#e0f2f1',
    color: '#00695c',
  }),
  ...(status === 'graded' && {
    backgroundColor: '#e8eaf6',
    color: '#283593',
  }),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '6px',
  padding: theme.spacing(1, 2),
  fontWeight: 500,
  textTransform: 'none',
  backgroundColor: '#0288d1',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#0277bd',
  },
  '&:disabled': {
    backgroundColor: '#b0bec5',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: '6px',
  padding: theme.spacing(1, 2),
  fontWeight: 500,
  textTransform: 'none',
  borderColor: '#757575',
  color: '#757575',
  '&:hover': {
    borderColor: '#424242',
    backgroundColor: '#f5f5f5',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiInputLabel-root': {
    color: '#616161',
    fontWeight: 500,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '6px',
    '& fieldset': { borderColor: '#bdbdbd' },
    '&:hover fieldset': { borderColor: '#757575' },
    '&.Mui-focused fieldset': { borderColor: '#0288d1' },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiInputBase-root': {
    borderRadius: '6px',
  },
  '& .MuiInputLabel-root': {
    color: '#616161',
    fontWeight: 500,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: '#bdbdbd' },
    '&:hover fieldset': { borderColor: '#757575' },
    '&.Mui-focused fieldset': { borderColor: '#0288d1' },
  },
}));

const TeacherHomeworkList = () => {
  const [homework, setHomework] = useState([]);
  const [grade, setGrade] = useState({ homeworkId: '', gradeValue: '', feedback: '' });
  const [gradingHomeworkId, setGradingHomeworkId] = useState(null);
  const gradeOptions = ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D', 'F'];

  useEffect(() => {
    const fetchHomework = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseUrl}api/teachers/gethomework`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setHomework(response.data.homework || []);
      } catch (error) {
        console.error('Error fetching homework:', error);
      }
    };
    fetchHomework();
  }, []);

  const handleGradeHomework = async (hwId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${baseUrl}api/teachers/reviewhomework`,
        { homeworkId: grade.homeworkId, score: grade.gradeValue, feedback: grade.feedback },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Homework graded successfully!');
      setGrade({ homeworkId: '', gradeValue: '', feedback: '' });
      setGradingHomeworkId(null);
      setHomework(
        homework.map((hw) =>
          hw._id === hwId ? { ...hw, status: 'graded', score: grade.gradeValue, feedback: grade.feedback } : hw
        )
      );
    } catch (error) {
      console.error('Error grading homework:', error);
      alert('Failed to grade homework.');
    }
  };

  const handleOpenFile = (fileUrl) => {
    const fullUrl = `${baseUrl}${fileUrl}`.replace(/([^:]\/)\/+/g, '$1');
    window.open(fullUrl, '_blank');
  };

  const handleGradeInputChange = (e) => {
    const { name, value } = e.target;
    setGrade({ ...grade, [name === 'score' ? 'gradeValue' : name]: value });
  };

  const handleStartGrading = (hwId) => {
    setGradingHomeworkId(hwId);
    setGrade({ homeworkId: hwId, gradeValue: '', feedback: '' });
  };

  const isGrading = (hwId) => gradingHomeworkId === hwId;
  const isGradeButtonDisabled = !grade.gradeValue || !grade.feedback || grade.homeworkId !== gradingHomeworkId;

  return (
    <Box sx={{ bgcolor: '#fafafa', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 600, color: '#212121', mb: 4 }}
        >
          Assigned Homework
        </Typography>
        {homework.length === 0 ? (
          <Typography variant="body1" color="#757575" align="center">
            No homework assigned yet.
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {homework.map((hw) => (
              <Grid item xs={12} sm={6} md={4} key={hw._id}>
                <HomeworkCard>
                  <CardContent sx={{ p: 0 }}>
                    <CardSection>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#212121' }}>
                          <PersonIcon sx={{ mr: 1, color: '#0288d1', verticalAlign: 'middle' }} />
                          {hw.assignedTo}
                        </Typography>
                        <StatusChip label={hw.status.toUpperCase()} status={hw.status} />
                      </Box>
                      <Typography variant="body2" color="#757575" sx={{ mb: 1 }}>
                        <AssignmentIcon sx={{ mr: 1, color: '#0288d1', verticalAlign: 'middle' }} />
                        {hw.title}
                      </Typography>
                      <Typography variant="body2" color="#757575">
                        <AccessTimeIcon sx={{ mr: 1, color: '#757575', verticalAlign: 'middle' }} />
                        Due: {new Date(hw.dueDate).toLocaleDateString()}
                      </Typography>
                    </CardSection>

                    {(hw.status === 'completed' || hw.status === 'graded') && (
                      <>
                        <Divider sx={{ borderColor: '#e0e0e0' }} />
                        <CardSection>
                          {hw.status === 'completed' && hw.submission && (
                            <Box>
                              {hw.submission.fileUrl && (
                                <Box display="flex" alignItems="center" mb={2}>
                                  <IconButton onClick={() => handleOpenFile(hw.submission.fileUrl)} aria-label="View File">
                                    <FileOpenIcon sx={{ color: '#0288d1' }} />
                                  </IconButton>
                                  <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => handleOpenFile(hw.submission.fileUrl)}
                                    sx={{ color: '#0288d1', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                                  >
                                    View Submission
                                  </Link>
                                </Box>
                              )}
                              {isGrading(hw._id) ? (
                                <Box>
                                  <StyledFormControl fullWidth>
                                    <InputLabel id="grade-select-label">Grade</InputLabel>
                                    <Select
                                      labelId="grade-select-label"
                                      name="score"
                                      value={grade.gradeValue}
                                      label="Grade"
                                      onChange={handleGradeInputChange}
                                    >
                                      {gradeOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </StyledFormControl>
                                  <StyledTextField
                                    label="Feedback"
                                    name="feedback"
                                    value={grade.feedback}
                                    onChange={handleGradeInputChange}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                  />
                                  <Box display="flex" gap={2} mt={2}>
                                    <ActionButton
                                      variant="contained"
                                      onClick={() => handleGradeHomework(hw._id)}
                                      disabled={isGradeButtonDisabled}
                                      startIcon={<GradeIcon />}
                                    >
                                      Submit Grade
                                    </ActionButton>
                                    <CancelButton
                                      variant="outlined"
                                      onClick={() => setGradingHomeworkId(null)}
                                    >
                                      Cancel
                                    </CancelButton>
                                  </Box>
                                </Box>
                              ) : (
                                <ActionButton
                                  variant="contained"
                                  startIcon={<GradeIcon />}
                                  onClick={() => handleStartGrading(hw._id)}
                                >
                                  Grade Homework
                                </ActionButton>
                              )}
                            </Box>
                          )}
                          {hw.status === 'graded' && hw.score && (
                            <Box>
                              <Typography variant="body2" sx={{ color: '#212121', mb: 1 }}>
                                <GradeIcon sx={{ mr: 1, color: '#0288d1', verticalAlign: 'middle' }} />
                                Grade: <strong>{hw.score}</strong>
                              </Typography>
                              {hw.feedback && (
                                <Typography variant="body2" color="#757575">
                                  <FeedbackIcon sx={{ mr: 1, color: '#757575', verticalAlign: 'middle' }} />
                                  {hw.feedback}
                                </Typography>
                              )}
                            </Box>
                          )}
                        </CardSection>
                      </>
                    )}
                  </CardContent>
                </HomeworkCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default TeacherHomeworkList;