import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Paper,Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import baseUrl from '../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom Styled DatePicker
const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5, 2),
  fontSize: '1rem',
  border: '1px solid #d1d5db',
  borderRadius: '8px',
  marginTop: theme.spacing(1),
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  color: '#374151',
  backgroundColor: '#fff',
  transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 3px ${theme.palette.primary.main}33`, // Subtle glow effect
  },
}));

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
  backgroundColor: '#fff',
  border: '1px solid #e5e7eb',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    fontWeight: 500,
    color: '#6b7280',
    transform: 'translate(14px, -6px) scale(0.75)',
    backgroundColor: '#fff',
    padding: '0 4px',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  '& .MuiInputLabel-root': {
    fontWeight: 500,
    color: '#6b7280',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: '#d1d5db' },
    '&:hover fieldset': { borderColor: '#9ca3af' },
    '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main },
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5, 4),
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: '12px',
  background: 'linear-gradient(90deg, #4f46e5 0%, #06b6d4 100%)',
  color: '#fff',
  textTransform: 'none',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(90deg, #4338ca 0%, #0891b2 100%)',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  },
}));

// Custom Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#4f46e5', // Indigo
    },
    secondary: {
      main: '#06b6d4', // Teal
    },
    background: {
      default: '#f9fafb',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      color: '#1f2937',
      fontSize: '2rem',
    },
    body1: {
      fontSize: '1rem',
      color: '#6b7280',
    },
  },
});

const AssignHomework = () => {
  const [students, setStudents] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    assignedTo: '',
    dueDate: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const studentsResponse = await axios.get(`${baseUrl}api/teachers/students`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStudents(studentsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load students');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dueDate: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const formattedFormData = {
        ...formData,
        dueDate: formData.dueDate instanceof Date ? formData.dueDate.toISOString() : formData.dueDate,
      };
      await axios.post(`${baseUrl}api/teachers/assign`, formattedFormData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Homework assigned successfully!');
      setFormData({
        title: '',
        description: '',
        assignedTo: '',
        dueDate: null,
      });
    } catch (error) {
      console.error('Error assigning homework:', error);
      alert('Failed to assign homework.');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', bgcolor: '#f9fafb' }}>
        <CircularProgress size={60} thickness={5} sx={{ color: '#4f46e5' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="#ef4444" align="center" sx={{ mt: 4, fontWeight: 600 }}>
        {error}
      </Typography>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          bgcolor: '#f9fafb',
          background: 'linear-gradient(135deg, #e0e7ff 0%, #dbeafe 100%)',
          p: { xs: 2, sm: 4 },
        }}
      >
        <Container maxWidth="sm">
          <StyledPaper elevation={3}>
            <Typography variant="h4" align="center" gutterBottom>
              Assign Homework
            </Typography>
            <form onSubmit={handleSubmit}>
              <StyledFormControl fullWidth required>
                <InputLabel id="student-select-label">Assign to Student</InputLabel>
                <Select
                  labelId="student-select-label"
                  id="student-select"
                  name="assignedTo"
                  value={formData.assignedTo}
                  label="Assign to Student"
                  onChange={handleInputChange}
                  sx={{
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#d1d5db' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#9ca3af' },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#4f46e5' },
                  }}
                >
                  {students.map((student) => (
                    <MenuItem key={student._id} value={student.student.enrollmentId}>
                      {student.student.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledTextField
                label="Homework Title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                fullWidth
                required
              />

              <StyledTextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                fullWidth
                required
                multiline
                rows={4}
              />

              <StyledFormControl fullWidth required>
                <InputLabel shrink sx={{ position: 'absolute', top: '-6px', left: '14px', bgcolor: '#fff', px: 0.5 }}>
                  Due Date
                </InputLabel>
                <CustomDatePicker
                  selected={formData.dueDate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Due Date"
                  minDate={new Date()} // Prevent past dates
                />
              </StyledFormControl>

              <SubmitButton type="submit" variant="contained" fullWidth>
                Assign Homework
              </SubmitButton>
            </form>
          </StyledPaper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AssignHomework;