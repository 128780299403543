import React, { useState,useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, InputBase, Avatar, Popover, Badge, Tooltip, useMediaQuery,Button } from '@mui/material';
import { Menu as MenuIcon, SearchOutlined, NotificationsOutlined, MessageOutlined, LogoutOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import axios from 'axios'; // To make API requests
import baseUrl from '../config';

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);  const [chatAnchorEl, setChatAnchorEl] = useState(null);
console.log(notifications,'notifications');

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => setNotificationAnchorEl(null);

  const handleChatClick = (event) => setChatAnchorEl(event.currentTarget);
  const handleChatClose = () => setChatAnchorEl(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate("/login");
  };

    // Fetch notifications from the backend API
    const fetchNotifications = async () => {
      setLoadingNotifications(true);
      try {
        const response = await axios.get(`${baseUrl}api/teachers/notifications`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Use token for authorization
          },
        });
        setNotifications(response.data.notifications);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      } finally {
        setLoadingNotifications(false);
      }
    };

    useEffect(() => {
      fetchNotifications(); 

    }, [])
    

  return (
    <AppBar position="sticky" sx={{
      background: 'linear-gradient(90deg, rgba(35, 41, 47, 0.9), rgba(173, 216, 230, 0.9))',      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
    }}>
      <Toolbar sx={{ justifyContent: 'space-between', padding: '12px 24px', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Toggle Sidebar" arrow>
            <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)} sx={{ mr: 2 }}>
              <MenuIcon sx={{ fontSize: '2rem', color: theme.palette.primary.light }} />
            </IconButton>
          </Tooltip>
          <SchoolIcon  sx={{  mr: 1,display: { xs: 'none', md: 'block' }, color: '#ffffff' }} />

          <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', md: 'block' }, color: '#ffffff' }}>
            Teacher Dashboard
          </Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#ffffff90',
          borderRadius: '30px',
          padding: '8px 16px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: isMobile ? '60%' : '320px',
          transition: 'width 0.3s ease'
        }}>
          <SearchOutlined sx={{ color: theme.palette.text.primary }} />
          <InputBase placeholder="Search..." sx={{
            marginLeft: '8px',
            width: '100%',
            color: theme.palette.text.primary,
            fontSize: '14px'
          }} />
        </Box>

        <Box sx={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          flexDirection: isMobile ? 'row' : 'row',
          justifyContent: isMobile ? 'space-around' : 'flex-end',
          width: isMobile ? '50%' : 'auto',
        }}>
          <Tooltip title="Notifications" arrow>
            <IconButton onClick={handleNotificationClick}>
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsOutlined sx={{ fontSize: '1.8rem', color: theme.palette.primary.light }} />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title="Chats" arrow>
            <IconButton onClick={handleChatClick}>
              <Badge badgeContent={1} color="error">
                <MessageOutlined sx={{ fontSize: '1.8rem', color: theme.palette.primary.light }} />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title={user?.name || 'User'} arrow>
            <IconButton onClick={handleClick}>
              <Avatar alt={user?.name || 'User'} src={user?.avatar || ''} sx={{ width: 36, height: 36 }} />
            </IconButton>
          </Tooltip>

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleLogout} sx={{ color: '#ff5c5c' }}>
              <LogoutOutlined sx={{ marginRight: '8px', fontSize: '1.3rem' }} /> Log Out
            </MenuItem>
          </Menu>

          <Popover
      open={Boolean(notificationAnchorEl)}
      anchorEl={notificationAnchorEl}
      onClose={handleNotificationClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        padding: '0px',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        sx={{
          width: '350px',
          padding: '1.5rem',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
          animation: 'fadeIn 0.3s ease-out',
        }}
      >
        {/* Header */}
        <Box sx={{
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
          padding: '1rem',
          borderRadius: '12px',
          marginBottom: '1rem',
        }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontFamily: '"Roboto", sans-serif',
              fontSize: '1.2rem',
              letterSpacing: '0.5px',
            }}
          >
            Notifications
          </Typography>
        </Box>

        {loadingNotifications ? (
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
            Loading...
          </Typography>
        ) : notifications.length === 0 ? (
          <Box
            sx={{
              padding: '0.8rem',
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
              marginBottom: '1rem',
              textAlign: 'center',
            }}
          >
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              No Notifications
            </Typography>
          </Box>
        ) : (
          notifications.slice(0, 5).map((notification, index) => (
            <Box
              key={index}
              sx={{
                padding: '1rem',
                backgroundColor: '#ffffff',
                borderRadius: '16px',
                marginBottom: '1rem',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                },
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {/* Notification Icon (Optional) */}
              <Box sx={{ width: '40px', height: '40px', marginRight: '1rem' }}>
                <SchoolIcon style={{ width: '100%', height: '100%' }} />
              </Box>
      
              <Box sx={{ flex: 1 }}>
                {/* Title */}
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    marginBottom: '0.5rem',
                    lineHeight: '1.3',
                  }}
                >
                  New Enrollment!
                </Typography>
      
                {/* Message */}
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: '0.9rem',
                    lineHeight: '1.5',
                    fontWeight: 400,
                  }}
                >
                  {notification.message}
                </Typography>
              </Box>
      
              {/* Optional: A small badge for urgency or status */}
              <Box
                sx={{
                  backgroundColor: '#ff5c5c',
                  color: '#fff',
                  padding: '0.4rem 0.8rem',
                  borderRadius: '12px',
                  fontSize: '0.75rem',
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '1rem',
                }}
              >
                New
              </Box>
            </Box>
          ))
        )}

        {/* Show 'See More' button if there are more than 5 notifications */}
        {notifications.length > 5 && (
          <Box sx={{ textAlign: 'center', marginBottom: '1rem' }}>
            <Button
              variant="outlined"
              // onClick={handleSeeMore}
              sx={{
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                },
              }}
            >
              See More
            </Button>
          </Box>
        )}

        {/* 'Clear All' Button */}
        {notifications.length > 0 && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              // onClick={handleClearAll}
              sx={{
                padding: '0.6rem 1.2rem',
                backgroundColor: '#ff4f58',
                color: '#fff',
                border: 'none',
                borderRadius: '10px',
                cursor: 'pointer',
                fontWeight: '600',
                fontSize: '14px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: '#ff2f3d',
                },
              }}
            >
              Clear All
            </Button>
          </Box>
        )}
      </Box>
    </Popover>
          <Popover
            open={Boolean(chatAnchorEl)}
            anchorEl={chatAnchorEl}
            onClose={handleChatClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Box sx={{
              width: '320px', padding: '1.5rem', backgroundColor: '#fff', borderRadius: '12px',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)', animation: 'fadeIn 0.3s ease-out'
            }}>
              <Typography variant="h6" sx={{
                fontWeight: '600', marginBottom: '1rem', textAlign: 'center', fontFamily: '"Roboto", sans-serif',
                color: theme.palette.text.primary
              }}>
                Chats
              </Typography>
              <Box sx={{
                padding: '0.8rem', backgroundColor: '#fafafa', borderRadius: '8px', marginBottom: '1rem'
              }}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                  No Messages
                </Typography>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
