import React, { useState, useEffect } from 'react'
import { Box, Grid, Card, Typography, Avatar, IconButton, CircularProgress } from '@mui/material'; // Import CircularProgress
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ClassIcon from '@mui/icons-material/Class';
import SchoolIcon from '@mui/icons-material/School';
import moment from 'moment-timezone'; // Import moment-timezone
import baseUrl from '../config';

// Helper function to format the date (keep this)
const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

function TodaysClasses() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [todaysClasses, setTodaysClasses] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null);     // Error state

    const handleDateChange = (direction) => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + direction);
        setSelectedDate(newDate);
    };

    const todayDateFormatted = formatDate(selectedDate);
    const selectedDateFormattedForAPI = moment(selectedDate).format('YYYY-MM-DD'); // Format for API query

    useEffect(() => {
        const fetchTodaysClasses = async () => {
            setLoading(true); // Set loading to true before fetch
            setError(null);     // Clear any previous errors
            try {
                const response = await fetch(`${baseUrl}api/teachers/todays-classes?date=${selectedDateFormattedForAPI}`,  {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                  },
                }); // Use formatted date
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setTodaysClasses(data.data);
            } catch (e) {
                console.error("Could not fetch today's classes:", e);
                setError(e); // Set error state
                setTodaysClasses([]); // Clear classes on error to avoid displaying old data
            } finally {
                setLoading(false); // Set loading to false after fetch (success or error)
            }
        };

        fetchTodaysClasses();
    }, [selectedDateFormattedForAPI]); // Re-fetch when selectedDateFormattedForAPI changes

    if (loading) {
        return (
            <Card sx={{ padding: 4, boxShadow: 5, borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                <CircularProgress />
            </Card>
        );
    }

    if (error) {
        return (
            <Card sx={{ padding: 4, boxShadow: 5, borderRadius: 3 }}>
                <Typography color="error">Error loading today's classes.</Typography>
            </Card>
        );
    }


    return (
        <Card sx={{
            padding: 4,
            boxShadow: 5,
            borderRadius: 3,
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 2,
                borderBottom: '2px solid #b2ebf2'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: '#00bcd4', color: '#fff', marginRight: 1.5 }}>
                        <SchoolIcon />
                    </Avatar>
                    <Typography variant="h6" sx={{ fontWeight: 700, color: '#1a237e' }}>
                        Today's Classes
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton size="small" color="primary" onClick={() => handleDateChange(-1)}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, color: '#37474f' }}>
                        {todayDateFormatted}
                    </Typography>
                    <IconButton size="small" color="primary" onClick={() => handleDateChange(1)}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            </Box>

            <Box sx={{ marginTop: 2 }}>
                <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
                    {todaysClasses.map((classGroup, index) => ( // Iterate over class groups (per student/course)
                        <Grid item xs={3} key={index}>
                            {classGroup.classSchedules.map((schedule, scheduleIndex) => ( // Iterate over schedules within each group
                                <Box key={scheduleIndex} sx={{
                                    padding: 2,
                                    borderRadius: 2,
                                    backgroundColor: '#e8f0fe',
                                    boxShadow: 2,
                                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                    '&:hover': {
                                        boxShadow: 4,
                                        transform: 'scale(1.05)',
                                        backgroundColor: '#90CAF9'
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <Box sx={{ marginBottom: 1 }}>
                                        <ClassIcon sx={{ color: '#1E88E5', fontSize: 'large' }} />
                                        <strong>{classGroup.courseTitle || 'Course Title'}</strong> {/* Course Title */}
                                    </Box>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#1565C0' }}>
                                        {schedule.timeTeacher} {/* Class Time from API */}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#455A64' }}>
                                        {classGroup.studentName || 'Student Name'} {/* Student Name */}
                                    </Typography>
                                </Box>
                            ))}
                        </Grid>
                    ))}
                    {todaysClasses.length === 0 && !loading && !error && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ textAlign: 'center', color: '#757575' }}>
                                No classes scheduled for this day.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Card>
    );
}

export default TodaysClasses;