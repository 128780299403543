import React, { useState,useEffect } from 'react';
import { Box, Grid, Card,Button,Rating, CardContent, Typography, Avatar, IconButton, Stack, Divider, LinearProgress, CircularProgress, Chip ,Icon} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // For navigation to the profile update page


import { Edit } from '@mui/icons-material';
import baseUrl from '../config';

const UserProfileCard = () => {
  const [profile, setProfile] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    avatar: '',
    starRating: 0,
    languages: [],
    skills: [],
    bio: ''
  });
  console.log(profile, "profile");
  const navigate = useNavigate();  // Hook for programmatic navigation

  useEffect(() => {
    // Fetch profile data
    const fetchProfile = async () => {
      try {
        const { data } = await axios.get(baseUrl + 'api/teachers/profile', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setProfile(data);

        setFormData({
          name: data.name,
          avatar: data.avatar,
          starRating: data.starRating,
          languages: data.languages || [],
          skills: data.skills || [],
          bio: data.bio
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfile();
  }, []);


  const handleUpdateProfile = () => {
    navigate('/profile'); // Navigate to the teacher's profile page
  };
  return (
    <Card sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      background: 'linear-gradient(to right, #0F172A, rgba(173, 216, 230, 0.9))',
      color: 'white',
      borderRadius: 3,
      boxShadow: 4, // Subtle depth
      overflow: 'hidden',
      pl: 1,
      position: 'relative',
      '@media (max-width: 600px)': {
        maxWidth: '100%', // Full width for smaller screens
        p: 1, // Reduce padding for small screens
      },
    }}>
      <CardContent>
        {/* Avatar and Basic Info Section */}
        <Grid container spacing={2} alignItems="center" wrap="wrap"> {/* Increased spacing and wrap */}
          <Grid item>
            <Avatar
              alt="Teacher Name"
              src={`${baseUrl}${profile?.avatar}`}
              sx={{ width: 60, height: 60 }} // Smaller avatar size
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" component="div" noWrap>
              {profile?.name}
            </Typography>
            <Typography variant="body2">
            Educator ID: {profile?.uniqueId}
            </Typography>
          </Grid>
    
          <Box display="flex" justifyContent="space-between" mt={2}  sx={{
            textAlign: 'center',
            // background: '#f8f8f8',
            // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.04)',
            // borderRadius: 2,
            // p: 2,
            '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' },
            flexWrap: 'wrap', // Allow wrapping on smaller screens
            '@media (max-width: 600px)': {
              flexDirection: 'column', // Stack items vertically on small screens
            },
          }}>
              <Box sx={{ mr: 2, textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {profile?.totalClassesAssigned||0}
    </Typography>
    <Typography variant="caption" color="#fff">
      Total paid classes
    </Typography>
              </Box>
              <Box sx={{ mr: 2, textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {profile?.totalDemoClasses||0}
                </Typography>
                <Typography variant="caption" color="#fff">
                Total Demo Classes
                </Typography>
              </Box>  <Box sx={{ mr: 2, textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {profile?.lostClasses||0}
                </Typography>
                <Typography variant="caption" color="#fff">
                Lost Classes
                </Typography>
              </Box>  <Box sx={{ mr: 2, textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {profile?.totalClassesTaken||0}
                </Typography>
                <Typography variant="caption" color="#fff">
                Total Classes Taken
                </Typography>
              </Box>  <Box sx={{ mr: 2, textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {profile?.totalStudents||0}
                </Typography>
                <Typography variant="caption" color="#fff">
Total Student
                </Typography>
              </Box>
          </Box>
    
        </Grid>
    
        <Divider sx={{ my: 1 }} />
    
        {/* Education, Experience, Location, Languages Section */}
        <Grid container spacing={2} sx={{ mb: 0.8 }}>
          {/* Section Titles */}
      
             <Grid item xs={3} >
              <Typography variant="body1" fontWeight="bold">
              Education:
              </Typography>
            </Grid>
            <Grid item xs={3} >
              <Typography variant="body1" fontWeight="bold">
              Skills:
              </Typography>
            </Grid>
            <Grid item xs={3} >
              <Typography variant="body1" fontWeight="bold">
              Location :
              </Typography>
            </Grid>
            <Grid item xs={3} >
              <Typography variant="body1" fontWeight="bold">
              Languages:
              </Typography>
            </Grid>

        </Grid>
    
        {/* Section Content */}
        <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={3} >
              <Typography variant="body2" noWrap>
              no data
              </Typography>
            </Grid>   <Grid item xs={3} >
              <Typography variant="body2" noWrap>
              {formData.skills||"no data "}
              </Typography>

            </Grid>  
            
             <Grid item xs={3} >

              <Typography variant="body2" noWrap>
                    {profile?.country||"no data "}
              </Typography>
                            

            </Grid>   <Grid item xs={3} >
              <Typography variant="body2" noWrap>
              {formData?.languages||"no data "}

              </Typography>
            </Grid>
        </Grid>
    
        <Divider sx={{ my: 1 }} />
    
        {/* Reviews Section */}
        <Box justifyContent="space-between" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Rating value={5} readOnly precision={0.5} sx={{ color: '#FFD700' }} />
            <Typography variant="body2" sx={{ ml: 1, textDecoration: 'underline' }}> {profile?.reviews?.length} Review{profile?.reviews.length > 1 ? 's' : ''}
            </Typography>
            
          </Box>
    
          {/* Edit Button */}
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              sx={{      
                color: 'white', 
                textTransform: 'none', 
                borderRadius: 2, 
                "&:hover": {
                  backgroundColor: '#e65c00', // Darker hover effect
                },
                '@media (max-width: 600px)': {
                  width: '100%', // Full width button on small screens
                }
              }} // Adjust button width for small screens
              onClick={handleUpdateProfile}
            >
              Edit Profile
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
    
  );
};

export default UserProfileCard;
