import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Navbar from "./Components/Navbar";
import PendingAttendancePopup from "./Components/PendingAttandancePopup";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(isNonMobile); // Set default state based on screen size

  // Ensure that the sidebar is opened/closed when screen size changes
  useEffect(() => {
    setIsSidebarOpen(isNonMobile); // Open sidebar by default on desktop, close on mobile
  }, [isNonMobile]);

  return (
    <Box display={isNonMobile ? "flex" : "block"} >
      {/* Sidebar: Only show on non-mobile view */}
      <Sidebar
        user={"user"}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          display: 'flex',
          flexDirection: 'column',
          // height: '100vh',
        }}
      >
        <Navbar
  user={"user"}
  isSidebarOpen={isSidebarOpen}
  setIsSidebarOpen={setIsSidebarOpen}
  sx={{
    position: "sticky",
    top: 0, // Ensure the navbar sticks right at the top
    // zIndex: 1100, // Ensure the Navbar is on top of other elements
    width: '100%',
    backgroundColor: 'white', // Make sure it's visible
  }}
/>

        
        {/* Content */}
        <Box 
          sx={{ 
            // padding: '5px', 
            flexGrow: 1, 
            overflowY: 'hiddden', // Make content scrollable
          }}
        >
          <Outlet />
                      <PendingAttendancePopup/>
          
        </Box>
      </Box>
    </Box>  
  );
};

export default Layout;
