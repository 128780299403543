import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import axios from "axios";
import baseUrl from "../config";

const PendingAttendancePopup = ({ teacherId }) => {
  const [pendingAttendances, setPendingAttendances] = useState([]);
  const [open, setOpen] = useState(false);
  const [lateReason, setLateReason] = useState("");
  const [allMarked, setAllMarked] = useState(false);  // New state to track if all attendances are marked

  console.log(pendingAttendances, "pendingAttendances");

  // Fetch pending attendances when the component mounts
  useEffect(() => {
    const fetchPendingAttendances = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/teachers/pending-attendances`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        setPendingAttendances(response.data.pendingAttendances);
        if (response.data.pendingAttendances.length > 0) {
          setOpen(true); // Open the popup if there are pending attendances
        }
      } catch (error) {
        console.error("Error fetching pending attendances:", error);
      }
    };

    fetchPendingAttendances();
  }, []);

  // Handle marking attendance late
  const handleMarkAttendanceLate = async (enrollmentId, classScheduleId) => {
    try {
      await axios.post(
        `${baseUrl}api/teachers/mark-attendance-late`,
        {
          enrollmentId,
          classScheduleId,
          lateReason,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Remove the marked attendance from the pending list
      setPendingAttendances((prev) =>
        prev.filter(
          (att) =>
            !(
              att.enrollmentId === enrollmentId &&
              att.classScheduleId === classScheduleId
            )
        )
      );

      // Clear the late reason input
      setLateReason("");
    } catch (error) {
      console.error("Error marking attendance late:", error);
    }
  };

  // Close the popup when all pending attendances are cleared
  useEffect(() => {
    if (pendingAttendances.length === 0) {
      setAllMarked(true);  // All attendances have been processed
    } else {
      setAllMarked(false); // Some attendances are still pending
    }
  }, [pendingAttendances]);

  return (
    <Dialog open={open} onClose={() => {}} maxWidth="sm" fullWidth>
      <DialogTitle>Pending Attendances</DialogTitle>
      <DialogContent>
        {pendingAttendances.length === 0 ? (
          <Typography>No pending attendances.</Typography>
        ) : (
          <List>
            {pendingAttendances.map((attendance) => (
              <ListItem
                key={`${attendance.enrollmentId}-${attendance.classScheduleId}`}
              >
                <ListItemText
                  primary={`Student: ${attendance.student}`}
                  secondary={`Scheduled Date: ${new Date(
                    attendance.scheduledDate
                  ).toLocaleDateString()}`}
                />
                <TextField
                  label="Late Reason"
                  value={lateReason}
                  onChange={(e) => setLateReason(e.target.value)}
                  size="small"
                  sx={{ marginRight: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleMarkAttendanceLate(
                      attendance.enrollmentId,
                      attendance.classScheduleId
                    )
                  }
                >
                  Mark Late
                </Button>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          disabled={!allMarked}  // Disable the close button until all attendances are marked
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PendingAttendancePopup;
