import React from 'react';
import { Box, Grid, Card,Button, Typography, Avatar, IconButton,  Divider ,Chip ,Icon} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PeopleIcon from '@mui/icons-material/People';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'; // Trophy Icon
import StarIcon from '@mui/icons-material/Star'; // Star Icon
import ClassIcon from '@mui/icons-material/Class';     // For schedule box icon (or choose another icon)
import SchoolIcon from '@mui/icons-material/School'; // For header avatar
import {
  CalendarMonth as CalendarMonthIcon,
  Quiz as QuizIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  PendingActions as PendingActionsIcon,
  LocalHospital as LocalHospitalIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon,
} from '@mui/icons-material';
import UserProfileCard from '../Components/UserProfileCard';
import DemoConversion from '../Components/DemoConversion';
import TodaysClasses from '../Components/TodaysClasses';
import PendingAttendancePopup from '../Components/PendingAttandancePopup';

// Register chart.js elements
ChartJS.register(ArcElement, Tooltip, Legend);

const TestDashboard = () => {

    // Sample Data for Graphs
    const attendanceData = [
        { name: 'Present', value: 89 },
        { name: 'Absent', value: 8 },
        { name: 'Late', value: 3 },
    ];


    const doughnutColors = ['#4caf50', '#f44336', '#ff9800'];

const doughnutData = {
  labels: ['Present', 'Absent', 'Late'],
  datasets: [
    {
      data: attendanceData.map((entry) => entry.value),
      backgroundColor: doughnutColors,
      hoverOffset: 4,
    },
  ],
};

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2,padding:3 }}> {/* Flex container with wrap */}
              {/* <Box sx={{ padding: 3, backgroundColor: '#f5f5f5', fontFamily: 'Roboto, sans-serif' }}> */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
      {/* UserProfileCard */}

            <UserProfileCard/>
      </Grid>

      {/* Conversion Ratio */}
      <Grid item xs={12} md={4} lg={4}>
      <DemoConversion/>
  

</Grid>


         {/* Today's Class */}
         <Grid item xs={12}>
<TodaysClasses/>
</Grid>
 {/* Attendance This Week Section */}
<Grid item xs={12} md={6} lg={4}>
<Card sx={{
  borderRadius: 3,
  boxShadow: 5,
  background: 'linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%)',
  mx: 'auto',
  p: 2,
  pb: 5,
  // transition: 'transform 0.3s ease-in-out',
  // '&:hover': { transform: 'scale(1.05)' },
}}>
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="h6" sx={{ fontWeight: 700, color: '#1e293b' }}>
      Attendance This Week
    </Typography>
    <Button
      size="small"
      sx={{
        textTransform: 'none',
        color: '#1e293b',
        border: '2px solid #1e293b',
        '&:hover': { backgroundColor: '#e6f4f1', borderColor: '#1e293b' },
        borderRadius: 2,
      }}
    >
      This Week
    </Button>
  </Box>
  <Divider sx={{ my: 2 }} />
  <Box sx={{ mt: 4, backgroundColor: '#f5f5f5', border: '1px solid #e0e0e0', borderRadius: 2, p: 3 }}>
    <Typography variant="h6" sx={{ fontWeight: 600, color: '#1e293b' }}>
      Last 7 Days Report
    </Typography>
    <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
      {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => {
        const dayStatus = attendanceData[index] || 'noData'; // Fallback if undefined
        let bgColor = '#e0e0e0'; // Default: No Data
        let text = day;
        if (dayStatus === 'completed') bgColor = '#4caf50';
        if (dayStatus === 'absent') bgColor = '#f44336';
        if (dayStatus === 'rescheduled') bgColor = '#ff9800';
        if (dayStatus === 'noClass') bgColor = '#9e9e9e';
        return (
          <Box key={index} textAlign="center">
            <Box
              sx={{
                display: 'inline-block',
                width: 28,
                height: 28,
                backgroundColor: bgColor,
                color: '#fff',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 600,
                marginBottom: 1,
              }}
            >
              {text}
            </Box>
          </Box>
        );
      })}
    </Box>
  </Box>
  <Box display="flex" justifyContent="space-between" sx={{ mt: 3,}}>
  <Typography variant="body2" sx={{ fontWeight: 500, color: '#ccc' }}>
        No of total working days
      </Typography>
      </Box>
  <Box display="flex" justifyContent="space-between" sx={{ mt: 3, backgroundColor: '#f5f5f5', border: '1px solid #e0e0e0', borderRadius: 2, p: 2 }}>
    <Box textAlign="center">
      <Typography variant="body2" sx={{ fontWeight: 500, color: '#4caf50' }}>
        Present
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: 700, color: '#4caf50' }}>
        25
      </Typography>
    </Box>
    <Box textAlign="center">
      <Typography variant="body2" sx={{ fontWeight: 500, color: '#f44336' }}>
        Absent
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: 700, color: '#f44336' }}>
        2
      </Typography>
    </Box>
    <Box textAlign="center">
      <Typography variant="body2" sx={{ fontWeight: 500, color: '#ff9800' }}>
        Late
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: 700, color: '#ff9800' }}>
        1
      </Typography>
    </Box>
  </Box>
  <Box mt={3} display="flex" justifyContent="center">
  <Box sx={{ width: 240, height: 240 }}>
<Doughnut
  data={doughnutData}
  options={{
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom', // Change 'top' to 'bottom'
        labels: {
          font: {
            weight: 'bold',
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.label || '';
            if (context.parsed !== null) {
              label += `: ${context.parsed}`;
            }
            return label;
          },
        },
      },
    },
  }}
/>
</Box>
  </Box>
</Card>
</Grid>

        {/* Leaderboard */}
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{
                padding: 3, // Increased padding
                backgroundColor: '#f8f9fa', // Light grey background for card
                boxShadow: 3, // Slightly increased shadow
                borderRadius: 2, // Rounded corners
                border: '1px solid #e0e0e0' // Subtle border
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: 2,
                    borderBottom: '2px solid #e0e0e0',
                    marginBottom: 2
                }}>
                    <EmojiEventsIcon sx={{ color: '#ffc107', fontSize: '2rem', marginRight: 1 }} /> {/* Trophy Icon with gold color */}
                    <Typography variant="h6" color="primary">Oratrics Leaderboard</Typography>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, paddingY: 1 }}> {/* Added paddingY for spacing */}
                        <Avatar
                            alt="Anand Sharma"
                            src="https://randomuser.me/api/portraits/men/1.jpg" // Example profile image - replace with actual URLs
                            sx={{
                                marginRight: 1,
                                width: 40, // Increased avatar size
                                height: 40,
                                border: '2px solid #bbdefb' // Avatar border color
                            }}
                        />
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">Anand Sharma</Typography> {/* Bold name */}
                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                <StarIcon sx={{ color: '#ffca28', fontSize: '1rem', marginRight: 0.5 }} /> {/* Star Icon for title */}
                                Creative Writing Star
                            </Typography>
                            <Typography variant="body2" color="textSecondary">Grade: 7</Typography>
                        </Box>
                        <Typography variant="subtitle1" sx={{ marginLeft: 'auto', color: '#28a745', fontWeight: 'bold' }}>{/* Green color for percentage */}
                            97%
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, paddingY: 1 }}>
                        <Avatar
                            alt="Vijay Lakshmi"
                            src="https://randomuser.me/api/portraits/women/2.jpg" // Example profile image - replace with actual URLs
                            sx={{
                                marginRight: 1,
                                width: 40,
                                height: 40,
                                border: '2px solid #bbdefb'
                            }}
                        />
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">Vijay Lakshmi</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                <StarIcon sx={{ color: '#ffca28', fontSize: '1rem', marginRight: 0.5 }} />
                                Creative Writing Star
                            </Typography>
                            <Typography variant="body2" color="textSecondary">Grade: 7</Typography>
                        </Box>
                        <Typography variant="subtitle1" sx={{ marginLeft: 'auto', color: '#28a745', fontWeight: 'bold' }}>
                            95%
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingY: 1 }}>
                        <Avatar
                            alt="Aryan Patel"
                            src="https://randomuser.me/api/portraits/men/3.jpg" // Example profile image - replace with actual URLs
                            sx={{
                                marginRight: 1,
                                width: 40,
                                height: 40,
                                border: '2px solid #bbdefb'
                            }}
                        />
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">Aryan Patel</Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                <StarIcon sx={{ color: '#ffca28', fontSize: '1rem', marginRight: 0.5 }} />
                                Creative Writing Star
                            </Typography>
                            <Typography variant="body2" color="textSecondary">Grade: 7</Typography>
                        </Box>
                        <Typography variant="subtitle1" sx={{ marginLeft: 'auto', color: '#28a745', fontWeight: 'bold' }}>
                            93%
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </Grid>
        {/* Upcoming Events */}
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{
                padding: 3, // Increased padding
                backgroundColor: '#f8f9fa', // Light grey background for card
                boxShadow: 3, // Slightly increased shadow
                borderRadius: 2, // Rounded corners
                border: '1px solid #e0e0e0' // Subtle border
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: 2,
                    borderBottom: '2px solid #e0e0e0',
                    marginBottom: 2
                }}>
                    <EventAvailableIcon color="primary" sx={{ fontSize: '2rem', marginRight: 1 }} /> {/* Larger Icon */}
                    <Typography variant="h6" color="primary">Upcoming Events</Typography>
                </Box>
                <Box sx={{ marginTop: 2 }}>
                    <Box sx={{ display: 'flex', marginBottom: 2, alignItems: 'center', paddingY: 1, backgroundColor: '#e8f0fe', borderRadius: 1 }}> {/* Light blue background for event */}
                        <EventAvailableIcon color="primary" sx={{ marginRight: 2, paddingLeft: 1 }} /> {/* Icon spacing and background */}
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">Vacation Meeting</Typography> {/* Bold event name */}
                            <Typography variant="body2" color="textSecondary">
                                <Box component="span" color="primary" fontWeight="bold">Date:</Box> 07 July 2024 - 07 July 2024 {/* Bold "Date" */}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <Box component="span" color="primary" fontWeight="bold">Time:</Box> 09:10 AM - 10:50 AM {/* Bold "Time" */}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: 2, alignItems: 'center', paddingY: 1, backgroundColor: '#e8f0fe', borderRadius: 1 }}>
                        <PeopleIcon color="primary" sx={{ marginRight: 2, paddingLeft: 1 }} />
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">Parents, Teacher Meet</Typography>
                            <Typography variant="body2" color="textSecondary">
                                <Box component="span" color="primary" fontWeight="bold">Date:</Box> 15 July 2024
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <Box component="span" color="primary" fontWeight="bold">Time:</Box> 09:10 AM - 10:50 AM
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingY: 1, backgroundColor: '#e8f0fe', borderRadius: 1 }}>
                        <QueryStatsIcon color="primary" sx={{ marginRight: 2, paddingLeft: 1 }} />
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">Staff Meeting</Typography>
                            <Typography variant="body2" color="textSecondary">
                                <Box component="span" color="primary" fontWeight="bold">Date:</Box> 10 July 2024
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <Box component="span" color="primary" fontWeight="bold">Time:</Box> 09:10 AM - 10:50 AM
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
        {/* Leave Status */}
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ padding: 3, backgroundColor: '#fff', boxShadow: 3, borderRadius: 2, border: '1px solid #e0e0e0' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: '#1976d2' }}>
                    <Icon component={CalendarMonthIcon} sx={{ mr: 1, fontSize: '2rem' }} />
                    <Typography variant="h6" fontWeight="bold">Leave Status</Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: '#ffc107', color: '#fff', width: 30, height: 30, mr: 1 }}>
                                <Icon component={PendingActionsIcon} />
                            </Avatar>
                            <Typography variant="subtitle2" fontWeight="bold">Emergency Leave</Typography>
                        </Box>
                        <Chip label="Pending" size="small" color="warning" variant="outlined" />
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        <Icon component={CalendarMonthIcon} sx={{ mr: 0.5, fontSize: '1rem', verticalAlign: 'middle' }} />
                        Date: 15 Jun 2024
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: '#4caf50', color: '#fff', width: 30, height: 30, mr: 1 }}>
                                <Icon component={LocalHospitalIcon} />
                            </Avatar>
                            <Typography variant="subtitle2" fontWeight="bold">Medical Leave</Typography>
                        </Box>
                        <Chip label="Approved" size="small" color="success" variant="outlined" />
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        <Icon component={CalendarMonthIcon} sx={{ mr: 0.5, fontSize: '1rem', verticalAlign: 'middle' }} />
                        Date: 15 Jun 2024
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: '#4caf50', color: '#fff', width: 30, height: 30, mr: 1 }}>
                                <Icon component={LocalHospitalIcon} />
                            </Avatar>
                            <Typography variant="subtitle2" fontWeight="bold">Medical Leave</Typography>
                        </Box>
                        <Chip label="Approved" size="small" color="success" variant="outlined" />
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        <Icon component={CalendarMonthIcon} sx={{ mr: 0.5, fontSize: '1rem', verticalAlign: 'middle' }} />
                        Date: 16 Jun 2024
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: '#4caf50', color: '#fff', width: 30, height: 30, mr: 1 }}>
                                <Icon component={SentimentDissatisfiedIcon} />
                            </Avatar>
                            <Typography variant="subtitle2" fontWeight="bold">Not Well</Typography>
                        </Box>
                        <Chip label="Approved" size="small" color="success" variant="outlined" />
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        <Icon component={CalendarMonthIcon} sx={{ mr: 0.5, fontSize: '1rem', verticalAlign: 'middle' }} />
                        Date: 16 Jun 2024
                    </Typography>
                </Box>
            </Card>
        </Grid>
        {/* My Courses */}
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ padding: 3, backgroundColor: '#fff', boxShadow: 3, borderRadius: 2, border: '1px solid #e0e0e0' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, color: '#2e7d32' }}>
                    <Icon component={SchoolIcon} sx={{ mr: 1, fontSize: '2rem' }} />
                    <Typography variant="h6" fontWeight="bold">My Courses</Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Avatar sx={{ bgcolor: '#81c784', color: '#fff', width: 25, height: 25, mr: 1 }}>
                            <Icon component={AssignmentTurnedInIcon} sx={{ fontSize: '1.2rem' }} />
                        </Avatar>
                        <Typography variant="subtitle2" fontWeight="bold">Public Speaking</Typography>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Avatar sx={{ bgcolor: '#81c784', color: '#fff', width: 25, height: 25, mr: 1 }}>
                            <Icon component={AssignmentTurnedInIcon} sx={{ fontSize: '1.2rem' }} />
                        </Avatar>
                        <Typography variant="subtitle2" fontWeight="bold">Personality Enrichment</Typography>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: '#81c784', color: '#fff', width: 25, height: 25, mr: 1 }}>
                            <Icon component={AssignmentTurnedInIcon} sx={{ fontSize: '1.2rem' }} />
                        </Avatar>
                        <Typography variant="subtitle2" fontWeight="bold">Creative Writing</Typography>
                    </Box>
                </Box>
            </Card>
        </Grid>
            {/* Quiz */}
            <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ padding: 3, backgroundColor: '#fff', boxShadow: 3, borderRadius: 2, border: '1px solid #e0e0e0', textAlign: 'center' }}>
                    <Box sx={{ color: '#ff9800', mb: 2 }}>
                        <Icon component={QuizIcon} sx={{ fontSize: '2.5rem' }} />
                        <Typography variant="h6" fontWeight="bold" sx={{ display: 'inline', verticalAlign: 'top' }}>Quiz</Typography>
                    </Box>
                    <Typography variant="subtitle2" color="textSecondary">Total quiz's conducted</Typography>
                    <Typography variant="h4" fontWeight="bold" color="#3f51b5">50</Typography>
                </Card>
            </Grid>

            </Grid>

        </Box>
    );
};

export default TestDashboard;