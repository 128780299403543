import React from 'react'
import { Box, Grid, Card,Button,Rating, CardContent, Typography, Avatar, IconButton, Stack, Divider, LinearProgress, CircularProgress, Chip ,Icon} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function DemoConversion() {

    const demosConverted = 25;
    const demosConducted = 75;
    const conversionRatio = (demosConverted / demosConducted) * 100;
  
    const data = {
      datasets: [
        {
          data: [demosConverted, demosConducted - demosConverted],
          backgroundColor: ['#22C55E', '#EF4444'],
        },
      ],
    };
  
    const options = {
      cutout: '70%',
      plugins: { legend: { display: false } },
    };
  return (
    <Card sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        color: 'white',
        borderRadius: 3,
        boxShadow: 4, // Subtle depth
        overflow: 'hidden',
        pt:2,
        pb:2    ,
        
        position: 'relative',
        '@media (max-width: 600px)': {
          maxWidth: '100%', // Full width for smaller screens
          p: 1, // Reduce padding for small screens
        },
      }}>
        <CardContent>
          {/* Avatar and Basic Info Section */}
          <Grid container spacing={2} alignItems="center" wrap="wrap"> {/* Increased spacing and wrap */}
            <Grid item lg>
            <Box sx={{
          width: '70%', 
          height:"100%",
          '@media (max-width: 600px)': {
            width: '100%', // Full width on small screens
            marginBottom: 2, // Add some spacing below the doughnut chart
          },
        }}>
          <Doughnut data={data} options={options} />
        </Box>
            </Grid>
            <Grid  item lg >
            <Box sx={{
          flexDirection: 'column', 
          alignItems: 'flex-start',
          '@media (max-width: 600px)': {
            width: '100%', // Take full width on small screens
            marginLeft: 0, // Remove margin for small screens
            textAlign: 'center', // Center text for better readability
          },
        }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333', marginBottom: 1 }} gutterBottom>
            Demo Conversion Ratio - 20%
          </Typography>
          <Typography variant="body2" sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            color: '#22C55E', 
            fontWeight: '500', 
            marginBottom: 1 
          }}>
            ● Demos Converted: <span style={{ fontWeight: 'bold', marginLeft: 4 }}>{demosConverted}</span> 
          </Typography>
          <Typography variant="body2" sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            color: '#EF4444', 
            fontWeight: '500' 
          }}>
            ● Demos Conducted: <span style={{ fontWeight: 'bold', marginLeft: 4 }}>{demosConducted}</span> 
          </Typography>
        </Box>
            </Grid>
      
          
      
          </Grid>
      </CardContent></Card>  )
}

export default DemoConversion