import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Card,
  CardContent,
  LinearProgress,
  Badge,
  Chip,
  Divider,
  Avatar,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CalendarToday as CalendarIcon,
  School as SchoolIcon,
  Person as PersonIcon,
  Schedule as ScheduleIcon,
  Event as EventIcon,
  EventAvailable as EventAvailableIcon,
  Class as ClassIcon,
  Bookmark as BookmarkIcon,
  MenuBook as MenuBookIcon,
  Assignment as AssignmentIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px !important',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
  marginBottom: '16px',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  '&:before': {
    display: 'none',
  },
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.12)',
    transform: 'translateY(-2px)',
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    minHeight: '64px',
  },
  '& .MuiAccordionDetails-root': {
    padding: '16px 24px',
    backgroundColor: theme.palette.grey[50],
  },
}));

const TopicCard = styled(Card)(({ theme }) => ({
  borderRadius: '10px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
  marginBottom: '12px',
  transition: 'all 0.3s ease',
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
}));

const ProgressBarWithLabel = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[300],
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,
  },
}));

const ClassDashboard = () => {
  const location = useLocation();
  const { chapters, courseTitle, studentDetails } = location.state || { 
    chapters: [], 
    courseTitle: '', 
    studentDetails: {} 
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Calculate attendance percentage for progress bar
  const totalClasses = studentDetails.totalClasses || 30;
  const attendedClasses = studentDetails.attendedClasses || 26;
  const attendancePercentage = (attendedClasses / totalClasses) * 100;

  return (
    <Box sx={{ 
      p: { xs: 2, sm: 3 }, 
      bgcolor: 'grey.50', 
      minHeight: '100vh',
      backgroundImage: 'linear-gradient(to bottom, #f5f7fa 0%, #e4e8ed 100%)',
    }}>
      {/* Main Content */}
      <Box sx={{
        display: 'flex',
        flex: 1,
        flexDirection: { xs: 'column', md: 'row' },
        padding: { xs: '0', md: '20px' },
        gap: { xs: '20px', md: '30px' },
        alignItems: 'flex-start'
      }}>
        {/* Sidebar (Profile and Stats) */}
        <Paper
          elevation={6}
          sx={{
            width: { xs: '100%', md: 350 },
            maxWidth: { xs: '100%', md: 350 },
            padding: '24px',
            borderRadius: '16px',
            backgroundColor: 'background.paper',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
            position: { xs: 'relative', md: 'sticky' },
            top: { md: '20px' },
            alignSelf: 'flex-start',
            height: 'fit-content',
            maxHeight: 'calc(100vh - 40px)',
            overflow: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Avatar sx={{ 
              width: 56, 
              height: 56, 
              bgcolor: 'primary.main',
              mr: 2,
              fontSize: '1.5rem',
            }}>
              {studentDetails.studentName?.charAt(0) || 'S'}
            </Avatar>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  color: 'text.primary',
                }}
              >
                {studentDetails.studentName || 'Student Name'}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <SchoolIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                {courseTitle || 'Course Title'}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ 
              fontWeight: 600, 
              mb: 1, 
              display: 'flex', 
              alignItems: 'center',
              color: 'text.secondary',
            }}>
              <PersonIcon sx={{ fontSize: '1rem', mr: 1 }} />
              Student Details
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" color="text.secondary">Grade</Typography>
              <Chip label="8" size="small" />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" color="text.secondary">Enrollment ID</Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>#55715</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" color="text.secondary">Level</Typography>
              <Chip label="Advanced" color="primary" size="small" />
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ 
              fontWeight: 600, 
              mb: 1, 
              display: 'flex', 
              alignItems: 'center',
              color: 'text.secondary',
            }}>
              <ScheduleIcon sx={{ fontSize: '1rem', mr: 1 }} />
              Schedule
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <CalendarIcon sx={{ fontSize: '1rem', mr: 1, color: 'text.secondary' }} />
              <Typography variant="body2">
                Thursday, Tuesday
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <EventIcon sx={{ fontSize: '1rem', mr: 1, color: 'text.secondary' }} />
              <Typography variant="body2">
                04:30 PM - 05:30 PM
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ 
              fontWeight: 600, 
              mb: 2, 
              display: 'flex', 
              alignItems: 'center',
              color: 'text.secondary',
            }}>
              <ClassIcon sx={{ fontSize: '1rem', mr: 1 }} />
              Class Statistics
            </Typography>
            
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2" color="text.secondary">Total Classes</Typography>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>{totalClasses}</Typography>
              </Box>
            </Box>
            
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2" color="text.secondary">Attendance</Typography>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {attendedClasses} / {totalClasses} ({Math.round(attendancePercentage)}%)
                </Typography>
              </Box>
              <ProgressBarWithLabel 
                variant="determinate" 
                value={attendancePercentage} 
                sx={{ mt: 1 }} 
              />
            </Box>
            
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2" color="text.secondary">First Class</Typography>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>14 Mar 2023</Typography>
              </Box>
            </Box>
            
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2" color="text.secondary">Next Class</Typography>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>16 Jun 2024</Typography>
              </Box>
            </Box>
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            startIcon={<EventAvailableIcon />}
            sx={{
              mt: 2,
              borderRadius: '12px',
              textTransform: 'none',
              padding: '12px',
              fontSize: '1rem',
              fontWeight: 600,
              boxShadow: '0 4px 12px rgba(25, 118, 210, 0.2)',
              '&:hover': {
                boxShadow: '0 6px 16px rgba(25, 118, 210, 0.3)',
              },
            }}
          >
            Join Next Class
          </Button>
        </Paper>

        {/* Class Content */}
        <Box sx={{ 
          flex: 1, 
          width: '100%',
          boxSizing: 'border-box', 
          overflowY: 'auto', 
          maxHeight: 'calc(100vh - 40px)',
          borderRadius: '16px',
        }}>
          <Box sx={{ 
            backgroundColor: 'background.paper', 
            borderRadius: '16px',
            p: { xs: 2, md: 4 },
            mb: 3,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.06)',
          }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                color: 'text.primary',
                mb: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <MenuBookIcon sx={{ mr: 1.5, color: 'primary.main' }} />
              {courseTitle || 'Course Title'}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{
                mb: 3,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AssignmentIcon sx={{ fontSize: '1rem', mr: 1 }} />
              Showing learning materials for {totalClasses} sessions
            </Typography>
            
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              sx={{ mb: 3 }}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Curriculum" icon={<BookmarkIcon sx={{ fontSize: '1rem' }} />} iconPosition="start" />
              <Tab label="Assignments" icon={<AssignmentIcon sx={{ fontSize: '1rem' }} />} iconPosition="start" />
              <Tab label="Resources" icon={<LinkIcon sx={{ fontSize: '1rem' }} />} iconPosition="start" />
            </Tabs>
          </Box>

          {chapters.map((chapter, index) => (
            <StyledAccordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                sx={{ '& .MuiAccordionSummary-content': { alignItems: 'center' } }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flex: 1,
                  mr: 2,
                }}>
                  <Box sx={{ 
                    width: 40, 
                    height: 40, 
                    bgcolor: 'primary.light', 
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 2,
                    flexShrink: 0,
                  }}>
                    <Typography variant="body1" sx={{ 
                      fontWeight: 700, 
                      color: 'primary.main',
                    }}>
                      {index + 1}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ 
                      fontWeight: 600, 
                      color: 'text.primary',
                    }}>
                      {chapter.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <AssignmentIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                      {chapter.topics?.length || 0} topics
                    </Typography>
                  </Box>
                </Box>
                <Chip 
                  label={index === 0 ? "In Progress" : "Upcoming"} 
                  color={index === 0 ? "primary" : "default"} 
                  size="small" 
                />
              </AccordionSummary>
              <AccordionDetails>
                {chapter.topics?.map((topic, topicIndex) => (
                  <TopicCard key={topicIndex}>
                    <CardContent sx={{ '&:last-child': { pb: 2 } }}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mb: 1,
                      }}>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" sx={{ 
                            fontWeight: 600,
                            mb: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            {topic.completed ? (
                              <CheckCircleOutlineIcon sx={{ 
                                color: 'success.main', 
                                fontSize: '1.2rem',
                                mr: 1,
                              }} />
                            ) : (
                              <PlayCircleOutlineIcon sx={{ 
                                color: 'primary.main', 
                                fontSize: '1.2rem',
                                mr: 1,
                              }} />
                            )}
                            {topic.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ 
                            pl: 2.2,
                            mb: 1.5,
                          }}>
                            {topic.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end',
                        mt: 1,
                      }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          endIcon={<LinkIcon />}
                          href={topic.topicLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            borderRadius: '8px',
                            textTransform: 'none',
                            fontWeight: 500,
                            px: 2,
                            '&:hover': {
                              backgroundColor: 'primary.light',
                            },
                          }}
                        >
                          Open Course Material
                        </Button>
                      </Box>
                    </CardContent>
                  </TopicCard>
                ))}
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ClassDashboard;